<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex">
          <h4 class="text-white">Sinistro</h4>
        </div>
      </div>

      <div class="page-body">
        <div class="card form">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <h5>
                  <div class="form-subheader"><span>Detalhes</span></div>
                </h5>
              </div>

              <!-- Campos informativos -->
              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Número de Apólice</label>
                <div>{{ claim.number }}</div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Tipo de Seguro</label>
                <div>{{ claim.insurance_type_id }}</div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Seguradora</label>
                <div>{{ claim.insurer }}</div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Segurado</label>
                <div>{{ claim.insured }}</div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Data de Criação</label>
                <div>{{ claim.created_at }}</div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="font-weight-bold">Usuário</label>
                <div>{{ claim.user }}</div>
              </div>

              <div class="col-12 mb-4">
                <label class="font-weight-bold">Descrição</label>
                <div class="info-box p-3">{{ claim.description }}</div>
              </div>

            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <a @click="back()" class="btn btn-outline-secondary">
                  <i class="fa fa-angle-left"></i> Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";

export default {
  name: "details-claims",
  mixins: [commomsHelper, dateFormatter],
  data: () => ({
    claim: {
      number: 0,
      description: '',
      insurance_type_id: '',
      insurer: '',
      insured: '',
      created_at: '',
      user:'', 
    },
    translateInsuranceTypes: translateInsuranceTypes,
  }),
  created() {
    this.showClaims();
  },
  methods: {
    back() {
      this.$router.push({
        name: "list-claims",
      });
    },
    showClaims() {
      this.loading = true;
      policyService
        .showClaims(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.alertMessage = response.data.message;
            return;
          }
          var data = response.data.data;

          this.claim = {
            created_at: this.formatDateTimeLocal(data.created_at) ?? '',
            description: data.description ?? '',
            number: data.policy?.number ?? '',
            insurer: data.policy?.insurer_selected ?? '',
            insurance_type_id: this.getEnumKeyByEnumValue(
              this.translateInsuranceTypes,
              data.policy?.insurance_type_id
            ),
            insured: data.policy?.insured_name ?? '',
            user: data.user?.name ?? ''
          };
        })
        .catch(() => {
          this.alertMessage = "Não foi possível recuperar os sinistros.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
