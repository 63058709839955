<template>
  <div class="page-container">
    <div class="page-content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-10">
            <h1 class="text-white">
              <i class="mdi mdi-file-alert"></i> Sinistros
            </h1>
          </div>
        </div>
      </div>

      <!-- Alert Messages -->
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <!-- Loading Overlay -->
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <b-spinner label="Carregando..."></b-spinner>
      </div>

      <!-- Claims Table -->
      <div class="gray-table table-responsive responsive-padding" style="margin-bottom: 50px; width: 100%;">
        <table class="custom-table custom-table-claims no-wrap">
          <thead>
            <tr>
              <th>Apólice</th>
              <th>Descrição</th>
              <th>Tipo de Seguro</th>
              <th class="text-center">Seguradora</th>
              <th>Segurado</th>
              <th>Usuário</th>
              <th>Data de criação</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ item.number }}</td>
              <td style="white-space: normal;" :title="item.description">{{ truncateText(item.description) }}</td>
              <td>{{ item.insurance_type_id }}</td>
              <td>{{ item.insurer_selected }}</td>
              <td>{{ item.insured_name }}</td>
              <td>{{ item.user_name }}</td>
              <td>{{ item.created_at }}</td>
              <td class="text-center">
                <button 
                  class="btn btn-action-gray"
                  @click="redirect('show-claim', { claimId: item.id })"
                  v-b-tooltip.hover
                  title="Visualizar Sinistro"
                >
                  <i class="fas fa-eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="m-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
          ></b-pagination>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "list-claims",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    alertMessage: null,
    loading: false,
    translateInsuranceTypes: translateInsuranceTypes,
  }),
  created() {
    this.getClaims();
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.items.slice(start, end);
    },
  },
  methods: {
    getClaims() {
      this.loading = true;
      policyService
        .getClaims()
        .then((response) => {
          if (response.data.error) {
            this.alertMessage = response.data.message;
            return;
          }
          this.formatList(response.data);
        })
        .catch(() => {
          this.alertMessage = "Não foi possível recuperar os sinistros.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatList(data) {
      this.items = data.map((element) => ({
        id: element.id,
        number: element.policy.number,
        insurance_type_id: this.getEnumKeyByEnumValue(
          this.translateInsuranceTypes,
          element.policy.insurance_type_id
        ),
        description: element.description,
        insurer_selected: element.policy.insurer_selected,
        insured_name: element.policy.insured_name,
        user_name: element.user ? element.user.name : '',
        created_at: this.formatDateTimeLocal(element.created_at),
      }));
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    truncateText(text, maxChar = 28) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
  },
};
</script>

<style>

</style>
