<template>
  <div class="page-container">
    <div class="page-content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-10">
            <h1 class="text-white">
              <i class="mdi mdi-file-check"></i> Certidões
            </h1>
          </div>
        </div>
      </div>

      <!-- Alert Messages -->
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <!-- Loading Overlay -->
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <b-spinner label="Carregando..."></b-spinner>
      </div>

      <!-- Certificates Table -->
      <div class="gray-table table-responsive responsive-padding" style="margin-bottom: 50px; width: 100%;">
        <table class="custom-table custom-table-certificates no-wrap">
          <thead>
            <tr>
              <th>Identificador</th>
              <th>Nome</th>
              <th class="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ item.identifier }}</td>
              <td>{{ item.name }}</td>
              <td class="text-center">
                <button
                  class="btn btn-action-gray"
                  @click="redirect('edit-certificate', { identifier: item.identifier })"
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button
                  class="btn btn-action-gray"
                  @click="redirect('details-certificate', { identifier: item.identifier })"
                  v-b-tooltip.hover
                  title="Detalhes"
                >
                  <i class="fas fa-eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="m-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
          ></b-pagination>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import commonsService from "@/services/commons";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "list-certificates",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    alertMessage: null,
    loading: false,
  }),
  created() {
    this.getCertificates();
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.items.slice(start, end);
    },
  },
  methods: {
    getCertificates() {
      this.loading = true;
      commonsService
        .insurers({ has_integration: true })
        .then((response) => {
          if (response.data.error) {
            this.alertMessage = response.data.message;
            return;
          }
          this.formatList(response.data);
        })
        .catch(() => {
          this.alertMessage = "Não foi possível recuperar as certidões.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatList(data) {
      this.items = Object.keys(data).map((item) => ({
        identifier: item,
        name: data[item],
      }));
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  },
};
</script>

<style>
</style>
