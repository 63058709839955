<template>
  <div class="page-container">
    <div class="page-content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row space-around d-flex">
          <div class="col-10">
            <h1 class="text-white">
              <i class="mdi mdi-gavel"></i> Apoio Jurídico
            </h1>
          </div>
          <div class="col-2 text-right">
            <button
              v-if="$can('process-import')"
              @click="openImportProcessesModal()"
              class="btn btn-outline-white ml-auto"
            >
              <i class="far fa-file-excel"></i>
              <span class="d-none d-md-inline"> Importar Documento</span>
            </button>
          </div>
        </div>
        <div class="d-block text-white text-right mb-2" v-if="last_import">
          <small v-html="MsgLastImport"></small>
        </div>
      </div>

      <!-- Alert Messages -->
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <!-- Loading Overlay -->
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <b-spinner label="Carregando..."></b-spinner>
      </div>

      <!-- Processes Table -->
      <div
        class="gray-table table-responsive responsive-padding"
        style="margin-bottom: 50px; width: 100%;"
      >
        <table class="custom-table custom-table-processes no-wrap">
          <thead>
            <tr>
              <th>Número do Processo</th>
              <th>Partes Polo Ativo</th>
              <th>Pedido Substituição</th>
              <th>Tipo Recurso</th>
              <th>Valor Depósito</th>
              <th class="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ item.number }}</td>
              <td>{{ item.active_part }}</td>
              <td>{{ item.substitution_order }}</td>
              <td>{{ item.resource_types }}</td>
              <td>{{ formatCurrency(item.value_deposit) }}</td>
              <td class="text-center">
                <button
                  v-if="$can('process-show')"
                  class="btn btn-action-gray"
                  @click="redirect('details-process', { processId: item.id })"
                  v-b-tooltip.hover
                  title="Visualizar"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <button
                  v-if="$can('process-delete')"
                  class="btn btn-action-gray"
                  @click="showModalDestroy({ item })"
                  v-b-tooltip.hover
                  title="Apagar"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="m-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
          ></b-pagination>
        </nav>
      </div>
    </div>

    <ImportProcessesModal
      v-model="showImportProcessModal"
      @processesImported="getProcesses()"
    />

    <!-- Modal para exclusão -->
    <b-modal
      id="show-modal"
      ref="showModal"
      size="sm"
      class="mt-4"
      hide-footer
      centered
    >
      <template #modal-title> Excluir processo </template>
      <div class="card card-termo-uso mt-1 mb-5">
        <p class="my-4">{{ deletion_data }}</p>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            @click="$bvModal.hide('show-modal')"
            class="btn btn-outline-secondary"
          >
            <i class="fa fa-angle-left mr-2"></i> Voltar
          </button>
          <button
            v-if="$can('process-delete')"
            @click="destroyProcess()"
            class="btn btn-primary ml-2"
          >
            Excluir <i class="fa fa-angle-right ml-2"></i>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import jurimetryService from "@/services/jurimetry";
import numericFormatterMixin from "@/mixins/numericFormatter";
import uiHelperMixin from "@/mixins/uiHelper";
import dateFormatter from "@/mixins/dateFormatter";
import ImportProcessesModal from "@/components/Jurimetry/ImportProcesses/Index.vue";

export default {
  name: "list-jurimetries",
  components: { ImportProcessesModal },
  mixins: [numericFormatterMixin, dateFormatter, uiHelperMixin],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    deletion_data: "",
    current_deletion: null,
    showImportProcessModal: false,
    last_import: null,
    alertMessage: null,
    holder_document: "",
    loading: false,
  }),
  created() {
    this.getProcesses();
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.items.slice(start, end);
    },
    MsgLastImport() {
      return this.last_import
        ? `Último processo importado em <strong>${this.last_import.toLocaleDateString("pt-BR")}</strong>`
        : "-";
    },
  },
  methods: {
    getProcesses() {
      this.resetAlert();
      jurimetryService
        .getProcesses()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
          this.last_import = this.getLastCreatedDate( this.items );
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as processos.");
        })
        .finally(() => {
          this.holder_document = "";
        });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    openImportProcessesModal() {
      this.showImportProcessModal = true;
    },
    showModalDestroy(val) {
      this.current_deletion = val.item.id;
      this.deletion_data =
        "Deseja realmente excluir os dados do processo " +
        val.item.number +
        " ?";
      this.$refs.showModal.show();
    },
    destroyProcess() {
      this.loading = true;
      this.$refs.showModal.hide();
      jurimetryService
        .destroyProcess(this.current_deletion)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getProcesses();
        })
        .catch(() => {
          this.showError("Não foi possível remover este processo.");
        })
        .finally(() => {
          this.current_deletion = null;
          this.loading = false;
        });
    },
    filterProcesses() {
      this.getProcesses();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.holder_document = "";
      this.getProcesses();
    },
  },
};
</script>

<style></style>
